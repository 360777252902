import React, { useState } from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"
import axios from "axios"
import { Formik } from "formik"
import * as Yup from "yup"

const PageContact = () => {
  let [formState, setFormState] = useState({
    state: "notSubmitted",
    response: false,
    error: false,
  })

  const ContactSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    notes: Yup.string().required("Required"),
  })

  const handleSubmit = values => {
    axios.post("/api/website/contact", values).then(
      res => {
        setFormState({ state: "success", response: res })
      },
      err => {
        setFormState({ state: "error", error: err.response.data.message })
      }
    )
  }

  let computedFormState = () => {
    switch (formState.state) {
      case "success":
        return (
          <div className="bg-gray-900 rounded-lg p-6 lg:p-8 rich-text">
            <h3>Thanks for contacting us!</h3>
            <p>We will get back with you as soon as possible.</p>
            <p>
              If you need immediate assistance, please feel free to call us.
            </p>
          </div>
        )
      case "error":
      case "notSubmitted":
        return (
          <>
            {"error" === formState.state ? (
              <div className="bg-gray-900 rounded-lg mb-6 lg:mb-8 p-6 lg:p-8 rich-text">
                <h3>There was a problem with your submission</h3>
                <p>
                  {formState.error ||
                    `Please try again later or feel free to give us a call!`}
                </p>
              </div>
            ) : (
              false
            )}

            <Formik
              initialValues={{
                name: "",
                phone: "",
                company: "",
                email: "",
                howDidYouHearAboutUs: "",
                notes: "",
              }}
              validationSchema={ContactSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form className="form" onSubmit={handleSubmit}>
                  <div className="lg:flex lg:justify-start">
                    <div
                      className={`input-field lg:flex-1 ${
                        errors.name && touched.name ? "input-field--error" : ""
                      }`}
                    >
                      <label htmlFor="form-contact-name">Name</label>
                      <input
                        id="form-contact-name"
                        name="name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {errors.name && touched.name ? (
                        <div className="text-red-500 mt-2">{errors.name}</div>
                      ) : (
                        false
                      )}
                    </div>

                    <div
                      className={`input-field lg:ml-6 lg:flex-1 ${
                        errors.phone && touched.phone
                          ? "input-field--error"
                          : ""
                      }`}
                    >
                      <label htmlFor="form-contact-phone">Phone</label>
                      <input
                        id="form-contact-phone"
                        name="phone"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                      {errors.phone && touched.phone ? (
                        <div className="text-red-500 mt-2">{errors.phone}</div>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                  <div className="lg:flex lg:justify-start">
                    <div
                      className={`input-field lg:flex-1 ${
                        errors.company && touched.company
                          ? "input-field--error"
                          : ""
                      }`}
                    >
                      <label htmlFor="form-contact-company">Company</label>
                      <input
                        id="form-contact-company"
                        name="company"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company}
                      />
                      {errors.company && touched.company ? (
                        <div className="text-red-500 mt-2">
                          {errors.company}
                        </div>
                      ) : (
                        false
                      )}
                    </div>

                    <div
                      className={`input-field lg:ml-6 lg:flex-1 ${
                        errors.email && touched.email
                          ? "input-field--error"
                          : ""
                      }`}
                    >
                      <label htmlFor="form-contact-email">Email</label>
                      <input
                        id="form-contact-email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email ? (
                        <div className="text-red-500 mt-2">{errors.email}</div>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                  <div
                    className={`input-field lg:flex-1 ${
                      errors.howDidYouHearAboutUs &&
                      touched.howDidYouHearAboutUs
                        ? "input-field--error"
                        : ""
                    }`}
                  >
                    <label htmlFor="form-contact-howDidYouHearAboutUs">
                      How did you hear about us?
                    </label>
                    <input
                      id="form-contact-howDidYouHearAboutUs"
                      name="howDidYouHearAboutUs"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.howDidYouHearAboutUs}
                    />
                    {errors.howDidYouHearAboutUs &&
                    touched.howDidYouHearAboutUs ? (
                      <div className="text-red-500 mt-2">
                        {errors.howDidYouHearAboutUs}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div
                    className={`input-field lg:flex-1 ${
                      errors.notes && touched.notes ? "input-field--error" : ""
                    }`}
                  >
                    <label htmlFor="form-contact-notes">
                      Comments/Questions:
                    </label>
                    <textarea
                      id="form-contact-notes"
                      name="notes"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.notes}
                    ></textarea>
                    {errors.notes && touched.notes ? (
                      <div className="text-red-500 mt-2">{errors.notes}</div>
                    ) : (
                      false
                    )}
                  </div>
                  <button className="block w-full lg:w-auto flex-1 lg:flex-initial mt-6 rounded px-4 py-2 bg-blue-500 hover:bg-blue-400 ml-auto text-white">
                    Contact Us
                  </button>
                </form>
              )}
            </Formik>
          </>
        )
    }
  }

  return (
    <Layout>
      <PageTitle
        title="Contact"
        description="Send us a message below and we'll get back to you right away."
      ></PageTitle>
      <ContentBlock className="lg:flex">
        <div className="mb-6 lg:mr-12 lg:w-1/3 rich-text">
          <p>
            <strong>Towbook</strong>
            <br />
            911 Military St, Floor 2
            <br />
            Port Huron, MI 48060
          </p>
          <p>
            <a href="tel:+18103205063">(810) 320-5063</a>
          </p>
          <p>
            <a href="mailto:support@towbook.com">support@towbook.com</a>
          </p>
          <p>
            If you have any questions or would like additional information,
            please fill out the form.
          </p>
        </div>
        <div className="lg:w-2/3">{computedFormState()}</div>
      </ContentBlock>
    </Layout>
  )
}

export default PageContact
